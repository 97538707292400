import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';

import { PassFormData } from 'api/userApi';
import ErrorMessage from 'components/widget/ErrorMessage';
import { customStyles } from 'util/styleUtil';

import { passwordUpdateSchema } from './resolvers/MypageResolvers';

type PasswordModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: PassFormData) => void;
};

export default function PasswordModal({
  isOpen,
  onClose,
  onSubmit,
}: PasswordModalProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: passwordUpdateSchema });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
      <section>
        <div className={'pop-wrap small-pop'}>
          <div className={'pop-header'}>
            <h2>
              <span>{'비밀번호 변경'}</span>
            </h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={'pop-body'}>
              <table className={'tbl-type-05 input-design input-small w100'}>
                <caption>{'비밀번호 변경'}</caption>
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <p>{'현재 비밀번호'}</p>
                    </th>
                    <td>
                      <input
                        type={'password'}
                        className={'input-box-type-01'}
                        {...register('oldPass')}
                        maxLength={50}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p>{'신규 비밀번호'}</p>
                    </th>
                    <td>
                      <input
                        type={'password'}
                        className={'input-box-type-01'}
                        {...register('newPass')}
                        maxLength={50}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p>{'비밀번호 확인'}</p>
                    </th>
                    <td>
                      <input
                        type={'password'}
                        className={'input-box-type-01'}
                        {...register('confirmPass')}
                        maxLength={50}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorMessage errors={errors} />
            </div>
            <div className={'pop-bottom input-large'}>
              <button type={'submit'} className={'btn-type-01'}>
                {'저장'}
              </button>
              <button
                type={'button'}
                className={'btn-type-02'}
                onClick={onClose}
              >
                {'취소'}
              </button>
            </div>
          </form>
        </div>
      </section>
    </ReactModal>
  );
}
