import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getNotice,
  NoticeFormData,
  useUpdateNoticeMutation,
} from 'api/noticeApi';
import { noticeKeys } from 'api/queryKeys/queryKeys';
import { Button } from 'components/widget/Button';
import TextEditor from 'components/widget/TextEditor';
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { isSuperAdminRole } from 'util/commonUtil';
import { decryptString } from 'util/encryptUtil';

import NoticeModal from './NoticeModal';

export default function NoticeDetail() {
  const params = useParams();
  const noticeId = Number(params?.noticeId ?? -1);
  const { role } = useRecoilValue(userInfoState);

  const [isOpen, setIsOpen] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: noticeKeys.detail(noticeId),
    queryFn: () => getNotice(noticeId),
  });

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const updateNoticeMutation = useUpdateNoticeMutation();
  const handleSubmit = (formData: NoticeFormData) => {
    updateNoticeMutation.mutate(formData, {
      onSuccess: (resp) => {
        if (resp.code === 200) {
          onCloseModal();
          refetch();
        } else {
          alert(resp.message);
        }
      },
    });
  };

  return (
    <section>
      <div className={'detail-header'}>
        <h2>{'공지사항 상세'}</h2>
        {isSuperAdminRole(decryptString(role)) && (
          <Button
            name={'수정'}
            className={'btn-type-03'}
            onClick={onOpenModal}
          />
        )}
      </div>
      <div className={'img-wrap device-info mt-20'}>
        <table className={'tbl-type-05 input-small'}>
          <caption>{'Notice Info'}</caption>
          <colgroup>
            <col width={'20%'} />
            <col />
            <col width={'20%'} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>{'제목'}</th>
              <td colSpan={3}>{data?.info.title}</td>
            </tr>
            <tr>
              <th>{'등록자명'}</th>
              <td>{data?.info.regUserName}</td>
              <th>{'등록일시'}</th>
              <td>{data?.info.regDate}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={'mt-20'}>
        <TextEditor value={data?.info.body ?? ''} isEnable={false} />
      </div>

      {isOpen && (
        <NoticeModal
          noticeId={noticeId}
          isOpen={isOpen}
          onSubmit={handleSubmit}
          onClose={onCloseModal}
        />
      )}
    </section>
  );
}
