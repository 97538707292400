import { DEVICE_TYPE, ORDER_STATE, ROLE_TYPE } from 'types/enumType';

export const getStrokeColor = (type: DEVICE_TYPE) => {
  switch (type) {
    case DEVICE_TYPE.FACTORY:
      return '#c8294b';
    case DEVICE_TYPE.AREA:
      return '#3461eb';
    case DEVICE_TYPE.DEVICE:
      return '#4bb505';
    default:
      return '#000';
  }
}

export const getStrokeColorInStatusBoard = (type: DEVICE_TYPE) => {
  switch (type) {
    case DEVICE_TYPE.FACTORY:
      return '#000000';
    case DEVICE_TYPE.AREA:
      return '#000000';
    case DEVICE_TYPE.DEVICE:
      return '#6a8fff';
    default:
      return '#000';
  }
}

export const isSuperAdminRole = (role: ROLE_TYPE | string) => role === ROLE_TYPE.SUPER_ADMIN;
export const isSuperRole = (role: ROLE_TYPE | string) => role === ROLE_TYPE.SUPER_ADMIN || role === ROLE_TYPE.SUPER_MANAGER;
export const isAdminRole = (role: ROLE_TYPE | string) => role === ROLE_TYPE.SUPER_ADMIN || role === ROLE_TYPE.SUPER_MANAGER || role === ROLE_TYPE.ADMIN;
export const isUserRole = (role: ROLE_TYPE | string) => role === ROLE_TYPE.USER;

export const LOCKER_LOCKING_IMAGE = `${process.env.PUBLIC_URL}/assets/images/lock-yellow.png`;
export const LOCKER_DISABLE_IMAGE = `${process.env.PUBLIC_URL}/assets/images/lock-gray.png`;
export const LOCKER_LOCK_IMAGE = `${process.env.PUBLIC_URL}/assets/images/lock-blue3.png`;
export const LOCKER_UNLOCK_IMAGE = `${process.env.PUBLIC_URL}/assets/images/unlock-red3.png`;
export const LOCKER_UNLOCKING_IMAGE = `${process.env.PUBLIC_URL}/assets/images/unlock-yellow.png`;
export const ZONE_SQUARE_IMAGE = `${process.env.PUBLIC_URL}/assets/images/zone-square.png`;


export const getLockerImgByConclusion = (lockCount: number, unlockCount: number, orderState?: ORDER_STATE): string => {
  if (!orderState) {
    return LOCKER_LOCK_IMAGE;
  }
  switch (orderState) {
    case ORDER_STATE.LOCKING:
      return LOCKER_LOCKING_IMAGE;
    case ORDER_STATE.LOCKED:
      return LOCKER_LOCK_IMAGE;
    case ORDER_STATE.APPROVED:
      return unlockCount > 0 ? LOCKER_UNLOCKING_IMAGE : LOCKER_UNLOCK_IMAGE;
    default:
      return LOCKER_LOCK_IMAGE;
  }
}