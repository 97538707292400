/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { lazy } from "react";

// export const retryLazy = (componentImport: any) =>
//   lazy(async () => {
//     const pageAlreadyRefreshed = JSON.parse(
//       window.sessionStorage.getItem('pageRefreshed') || 'false'
//     )
//     try {
//       const component = await componentImport()
//       window.sessionStorage.setItem('pageRefreshed', 'false')
//       return component
//     } catch (error) {
//       if (!pageAlreadyRefreshed) {
//         window.sessionStorage.setItem('pageRefreshed', 'true')
//         return window.location.reload()
//       }
//       throw error
//     }
//   })

// 추후 아래 사이트 참조하여 적용 필요
// https://www.codemzy.com/blog/fix-chunkloaderror-react
// export const lazyRetry = function (componentImport: any) {
//   return new Promise((resolve, reject) => {
//     const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');


//     componentImport().then((component: unknown) => {
//       window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
//       resolve(component);
//     }).catch((error: any) => {
//       if (!hasRefreshed) {
//         window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
//         return window.location.reload();
//       }
//       reject(error);
//     })
//   })
// }

export const retryLazy = (componentImport: any, componentKey?: string) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        `retry-${componentKey ?? 'lazy'}-refreshed`
      ) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        `retry-${componentKey ?? 'lazy'}-refreshed`,
        'false'
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          `retry-${componentKey ?? 'lazy'}-refreshed`,
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });