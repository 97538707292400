import { useMutation } from "@tanstack/react-query";

import { ChangeHistory } from "model/ChangeHistory";
import { Log } from "model/Log";
import { Order } from "model/Order";

import { BaseResp } from './BaseResp';
import { client } from "./client";
import { downloadExcel } from "./commonApi";
import { LogSearchRequest } from "./request/LogSearchRequest";
import { LotoOrderSearchRequest } from "./request/LotoOrderSearchRequest";
import { UserListResp } from "./userApi";

export interface OrderFormData {
  factoryId: number;
  orderId: number;
  name: string;
  workDate: string;
  equipIds: number[];
  detail: string;
}

export interface AgentRequest {
  orderId: number | null;
  originId: number | null;
  lockerId?: number | null;
  transferId?: number | null;
}

export interface LotoOrderListResp extends BaseResp {
  totalCount: number;
  list: Order[];
}

export interface LotoOrderResp extends BaseResp {
  info: Order;
}

export interface LogResp extends BaseResp {
  info: Log;
}

export interface OrderChangeHistoryResp extends BaseResp {
  list: ChangeHistory[];
  totalCount: number;
}

export const getLotoOrderList = (req: LotoOrderSearchRequest): Promise<LotoOrderListResp> => client.get('v1/orders', { params: req });
export const getLotoOrder = (orderId: number): Promise<LotoOrderResp> => client.get(`v1/order/${orderId}`);
export const getOrderChangeHistory = (req: LogSearchRequest): Promise<OrderChangeHistoryResp> => client.get(`v1/order/${req.id}/log`, { params: req })

const registerOrder = (
  order: OrderFormData,
): Promise<BaseResp> => client.post('/v1/order', { ...order, equipIds: order.equipIds?.toString() });
export const useRegisterOrderMutation = () => useMutation({ mutationFn: registerOrder });

const updateOrder = (
  order: OrderFormData,
): Promise<BaseResp> => client.post(`/v1/order/${order.orderId}`, { ...order, equipIds: order.equipIds?.toString() });
export const useUpdateOrderMutation = () => useMutation({ mutationFn: updateOrder });
const deleteOrder = (orderId: number): Promise<BaseResp> => client.delete(`v1/order/${orderId}`);
export const useDeleteOrderMutation = () => useMutation({ mutationFn: deleteOrder });

export const getAgentList = (req: AgentRequest): Promise<UserListResp> => client.get('/v1/history/transfer/user', { params: req });
const setUnlockAgent = (req: AgentRequest): Promise<BaseResp> => client.post('/v1/history/transfer', { ...req });
const deleteUnlockAgent = (req: AgentRequest): Promise<BaseResp> => client.delete('v1/history/transfer', { params: req });

export const useSetUnlockAgentMutation = () => useMutation({ mutationFn: setUnlockAgent });
export const useDeleteUnlockAgent = () => useMutation({ mutationFn: deleteUnlockAgent });

export const downloadLotoLorderListExcel = (req: LogSearchRequest) =>
  downloadExcel(req, 'v1/order/excel', '작업허가서 목록');

// 부착 확정/취소
const confirmAttach = (orderId: number): Promise<BaseResp> => client.post(`/v1/order/${orderId}/lock`);
const cancelConfirmAttach = (orderId: number): Promise<BaseResp> => client.delete(`/v1/order/${orderId}/lock`);
// 제거 승인/취소
const approveDelete = (orderId: number): Promise<BaseResp> => client.post(`/v1/order/${orderId}/unlock`);
const cancelApproveDelete = (orderId: number): Promise<BaseResp> => client.delete(`/v1/order/${orderId}/unlock`);
// 제거 확정
const confirmDelete = (orderId: number): Promise<BaseResp> => client.post(`/v1/order/${orderId}/complete`);

export const useConfirmAttachMutation = () => useMutation({ mutationFn: confirmAttach });
export const useCancelConfirmAttachMutation = () => useMutation({ mutationFn: cancelConfirmAttach });
export const useApproveDeleteMutation = () => useMutation({ mutationFn: approveDelete });
export const useCancelApproveDeleteMutation = () => useMutation({ mutationFn: cancelApproveDelete });
export const useConfirmDeleteMutation = () => useMutation({ mutationFn: confirmDelete });



