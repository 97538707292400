import { ConclusionSearchRequest } from 'api/request/ConclusionSearchRequest';
import { CustomerSearchRequest } from 'api/request/CustomerSearchRequest';
import { DeviceSearchRequest } from 'api/request/DeviceSearchRequest';
import { InquirySearchRequest } from 'api/request/InquirySearchRequest';
import { LockerSearchRequest } from 'api/request/LockerSearchRequest';
import { LogSearchRequest } from 'api/request/LogSearchRequest';
import { LotoOrderSearchRequest } from 'api/request/LotoOrderSearchRequest';
import { NoticeSearchRequest } from 'api/request/NoticeSearchRequest';
import { UserSearchRequest } from 'api/request/UserSearchRequest';

export const dashboardKeys = {
  all: ['dashboard'] as const,
  image: (id: number) => [...dashboardKeys.all, 'image', id] as const,
  map: ['dashboard', 'map'] as const,
  status: (id: number) => [...dashboardKeys.all, 'status', id] as const,
  deviceList: (id: number) => [...dashboardKeys.all, 'device', 'list', id] as const,
  details: () => [...dashboardKeys.all, 'detail'] as const,
  detail: (id: number) => [...dashboardKeys.details(), id] as const,
};

export const conclusionKeys = {
  all: ['conclusions'] as const,
  list: (filters: ConclusionSearchRequest) =>
    [
      'conclusions',
      'list',
      { page: filters.page, count: filters.count, factoryId: filters.factoryId },
    ] as const,
  details: () => [...conclusionKeys.all, 'detail'] as const,
  detail: (id: number) => [...conclusionKeys.details(), id] as const,
};

export const statusBoardKeys = {
  all: ['statusBoardKeys'] as const,
  info: (customerId: number) =>
    [
      'statusBoardKeys',
      { customerId },
    ] as const,
};

export const lotoOrderKeys = {
  all: ['lotoOrders'] as const,
  list: (filters: LotoOrderSearchRequest) =>
    ['lotoOrders', 'list', { page: filters.page, count: filters.count, factoryId: filters.factoryId }] as const,
  details: () => [...lotoOrderKeys.all, 'detail'] as const,
  detail: (id: number) => [...lotoOrderKeys.details(), id] as const,
};


export const logKeys = {
  all: ['log'] as const,
  list: (filters: LogSearchRequest) =>
    [
      'log',
      'list',
      {
        page: filters.page,
        count: filters.count,
        lockerId: filters.lockerId,
        userId: filters.userId,
        id: filters.id,
        factoryId: filters.factoryId
      },
    ] as const,
};

export const lockerKeys = {
  all: ['lockers'] as const,
  list: (filters: LockerSearchRequest) =>
    ['lockers', 'list', { page: filters.page, count: filters.count, factoryId: filters.factoryId }] as const,
  details: () => [...lockerKeys.all, 'detail'] as const,
  detail: (id: number) => [...lockerKeys.details(), id] as const,
};

export const lockerLogKeys = {
  all: ['lockerLogs'] as const,
  list: (filters: LogSearchRequest) =>
    [
      'lockerLogs',
      'list',
      { page: filters.page, count: filters.count },
    ] as const,
};


export const deviceKeys = {
  all: ['devices'] as const,
  list: (filters: DeviceSearchRequest) =>
    ['devices', 'list', { factoryId: filters.factoryId }] as const,
  details: () => [...deviceKeys.all, 'detail'] as const,
  qrList: ['devices', 'qr', 'list'] as const,
  detail: (id: number) => [...deviceKeys.details(), id] as const,
};

export const areaKeys = {
  all: ['areas'] as const,
  list: (filters: DeviceSearchRequest) =>
    ['areas', 'list', { factoryId: filters.factoryId }] as const,
  positionList: ['areas', 'list', 'position'] as const,
  details: () => [...areaKeys.all, 'detail'] as const,
  detail: (id: number) => [...areaKeys.details(), id] as const,
};

export const factoryKeys = {
  all: ['factory'] as const,
  list: (customerId: number) =>
    ['factory', 'list', { customerId }] as const,
  details: () => [...factoryKeys.all, 'detail'] as const,
  detail: (id: number) => [...factoryKeys.details(), id] as const,
  imgPath: ['factory', 'detail', 'imgPath'] as const,
}

export const userKeys = {
  all: ['userKeys'] as const,
  list: (filters: UserSearchRequest) =>
    ['users', 'list', { page: filters.page, count: filters.count, factoryId: filters.factoryId }] as const,
  details: () => [...userKeys.all, 'detail'] as const,
  detail: (id: number) => [...userKeys.details(), id] as const,
  history: (req: LogSearchRequest) => [...userKeys.all, 'history', { page: req.page, count: req.count }] as const,
  permissionUserList: () => [...userKeys.all, 'permissionUser', 'list'] as const,
  info: ['userKeys', 'mypage'] as const,
};

export const userAuthKeys = {
  all: ['userAuthKeys'] as const,
  details: () => [...userAuthKeys.all, 'detail'] as const,
  detail: (id: number) => [...userAuthKeys.details(), id] as const,
};

export const userPermissionKeys = {
  all: ['userPermissionKeys'] as const,
  list: (userId: number) =>
    ['userPermission', 'list', { userId }] as const,
};

export const userLogKeys = {
  all: ['userLogs'] as const,
  list: (filters: LogSearchRequest) =>
    ['userLogs', 'list', { page: filters.page, count: filters.count }] as const,
};

export const customerKeys = {
  all: ['customerKeys'] as const,
  list: (filters: CustomerSearchRequest) =>
    ['customers', 'list', { page: filters.page, count: filters.count }] as const,
  logList: (filters: LogSearchRequest) =>
    ['customers', 'list', 'log', { page: filters.page, count: filters.count, id: filters.id }] as const,
  details: () => [...customerKeys.all, 'detail'] as const,
  detail: (id: number) => [...customerKeys.details(), id] as const,
};

export const adminKeys = {
  all: ['adminKeys'] as const,
  list: (filters: UserSearchRequest) =>
    ['admins', 'list', { page: filters.page, count: filters.count }] as const,
  details: () => [...adminKeys.all, 'detail'] as const,
  detail: (id: number) => [...adminKeys.details(), id] as const,
};

export const inquiryKeys = {
  all: ['inquiry'] as const,
  list: (filters: InquirySearchRequest) =>
    ['inquiry', 'list', { page: filters.page, count: filters.count }] as const,
  details: () => [...inquiryKeys.all, 'detail'] as const,
  detail: (id: number) => [...inquiryKeys.details(), id] as const,
};

export const noticeKeys = {
  all: ['notice'] as const,
  list: (filters: NoticeSearchRequest) =>
    ['notice', 'list', { page: filters.page, count: filters.count }] as const,
  details: () => [...noticeKeys.all, 'detail'] as const,
  detail: (id: number) => [...noticeKeys.details(), id] as const,
};

export const userInfoKeys = {
  all: ['useInfos'] as const,
  details: () => [...userInfoKeys.all, 'detail'] as const,
  detail: (id: number) => [...userInfoKeys.details(), id] as const,
};
