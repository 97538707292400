
export const convertColumnToName = (column: string): string => {
  switch (column) {
    case 'no':
      return 'No';
    case 'user':
      return '사용자';
    case 'lockerName':
      return 'Locker';
    case 'customer':
      return '고객사';
    case 'macAddr':
      return 'MAC Addr';
    case 'type':
      return '구분';
    case 'model':
      return 'Model';
    case 'state':
      return '이벤트';
    case 'result':
      return '결과'
    case 'stateUserName':
      return '사용자';
    case 'stateDate':
      return '등록일시';
    case 'regDate':
      return '등록일시';
    case 'regUserName':
      return '사용자';
    default:
      return ''
  }
}

export const getLockerTypeName = (key: string): string => {
  switch (key) {
    case 'B':
      return 'BOOKSHELF';
    case 'D':
      return 'DOOR';
    case 'L':
      return 'LOCKER';
    default:
      return 'ETC';
  }
}

export const columnAlignByName = (name: string): string => {
  if (name !== 'roleName' && (name.toLowerCase().includes('name') || name === 'account' || name === 'userEmail' || name === 'title' || name === 'inquireTitle' || name === 'result' || name === 'equipCode')) {
    return 'ta-left';
  }
  return 'ta-center';
}

export const getColWidthByName = (name: string): number | string => {
  switch (name) {
    case 'no':
      return 80;
    case 'state':
      return 10;
    case 'stateDate':
    case 'regDate':
      return 200;
    default:
      return '';
  }
}