import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { AgentRequest, getAgentList } from 'api/lotoOrderApi';
import { userKeys } from 'api/queryKeys/queryKeys';
import { Button } from 'components/widget/Button';
import { Locking } from 'model/Locking';
import { User } from 'model/User';
import { customStyles } from 'util/styleUtil';

type UnlockAgentSettingModalProps = {
  orderId: number;
  locking: Locking | null;
  isOpen: boolean;
  onSubmit: (originId: number, transferId: number) => void;
  onClose: () => void;
};

export default function UnlockAgentSettingModal({
  orderId,
  locking,
  isOpen,
  onSubmit,
  onClose,
}: UnlockAgentSettingModalProps) {
  const [req, setReq] = useState<AgentRequest>({
    orderId: null,
    originId: null,
    lockerId: null,
  });
  const [tmpUserList, setTmpUserList] = useState<User[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');

  const { data, refetch, isRefetching } = useQuery({
    queryKey: userKeys.permissionUserList(),
    queryFn: () => getAgentList(req),
    enabled: false,
  });

  useEffect(() => {
    if (!isOpen || !locking) {
      setTmpUserList([]);
      setSearchKey('');

      return;
    }

    setReq((prev) => ({
      ...prev,
      orderId,
      lockerId: locking.lockerId,
      originId: locking.lockUserId,
    }));

    setTimeout(() => {
      refetch();
    }, 100);
  }, [isOpen]);

  useEffect(() => {
    if (isRefetching) {
      return;
    }

    setTmpUserList(data?.list ?? []);
  }, [data?.list, isRefetching]);

  const handleSettingUnlockAgent = (userId: number) => {
    if (locking?.transUserId === userId) {
      alert('이미 지정된 대리인입니다.');
      return;
    }

    onSubmit(locking!.lockUserId, userId);
  };

  const onChangeSearchKey = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value.trim());
  };

  const onSearch = (event: FormEvent<HTMLFormElement>) => {
    // event.stopPropagation();
    event.preventDefault();

    if (!data || !data?.list || data?.list.length === 0) {
      setTmpUserList([]);
      return;
    }

    setTmpUserList(
      data.list.filter((user) => user.name.includes(searchKey) ?? []),
    );
  };

  return (
    <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
      <section>
        <div className={'pop-wrap middle-pop'}>
          <div className={'pop-header'}>
            <h2>
              <span>{'제거 대리인 지정'}</span>
            </h2>
          </div>
          <div className={'pop-body permission'}>
            {locking && (
              <div className={'img-wrap device-info'}>
                <table className={'tbl-type-05 input-small'}>
                  <caption>{'User Info'}</caption>
                  <colgroup>
                    <col width={'20%'} />
                    <col />
                    <col width={'20%'} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{'부착자 명'}</th>
                      <td>{locking.lockUserName}</td>
                      <th>{'휴대폰번호'}</th>
                      <td>{locking.lockUserPhone}</td>
                    </tr>
                    {locking.transUserId && (
                      <tr>
                        <th>{'제거 대리인'}</th>
                        <td>{locking.transUserName}</td>
                        <th>{'휴대폰번호'}</th>
                        <td>{locking.transUserPhone}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <form onSubmit={onSearch} className={'mt-20'}>
              <div className={'search-grid gtype-03'}>
                <article className={'search-card gc-3'}>
                  <input
                    type={'text'}
                    className={'input-box-type-01'}
                    placeholder={''}
                    value={searchKey}
                    onChange={onChangeSearchKey}
                    maxLength={50}
                  />
                </article>
                <button type={'submit'} className={'p-right btn-type-01'}>
                  {'SEARCH'}
                </button>
              </div>
            </form>
            <div className={'table-wrap mt-20 max-h-22dot5rem'}>
              <table className={'tbl-type-04 no-img'}>
                <caption>{'대리인 지정'}</caption>
                <colgroup>
                  <col />
                  <col />
                  <col width={'20%'} />
                </colgroup>
                <thead>
                  <tr>
                    <th aria-label={'th'} className={'center'}>
                      {'이름'}
                    </th>
                    <th className={'center'}>{'휴대폰 번호'}</th>
                    <th className={'center'}>{''}</th>
                  </tr>
                </thead>
                <tbody className={'tb-ov'}>
                  {tmpUserList.length === 0 ? (
                    <tr>
                      <td className={'center'} colSpan={3}>
                        {'데이터가 없습니다.'}
                      </td>
                    </tr>
                  ) : (
                    tmpUserList.map((user) => (
                      <tr key={user.account}>
                        <td>{user.name}</td>
                        <td>{user.phone}</td>
                        <td>
                          <Button
                            name={'지정'}
                            className={'btn-type-03 pos-static'}
                            onClick={() =>
                              handleSettingUnlockAgent(user.userId)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className={'pop-bottom input-large'}>
            <button type={'button'} className={'btn-type-02'} onClick={onClose}>
              {'닫기'}
            </button>
          </div>
        </div>
      </section>
    </ReactModal>
  );
}
