import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getDashboardStatus } from 'api/homeApi';
import { dashboardKeys } from 'api/queryKeys/queryKeys';
import { useRecoilState } from 'recoil';
import { userInfoState } from 'recoil/user/atom';

import dashboardItemList from './dashboardItemList';
import SidebarNavList from './SidebarNavList';
import StatusBoard from './StatusBoard';

export default function Sidebar() {
  const location = useLocation();
  const [state, setState] = useRecoilState(userInfoState);

  const { data: dashboardData, refetch: refetchDashboard } = useQuery({
    queryKey: dashboardKeys.status(Number(state.factoryId)),
    queryFn: () => getDashboardStatus(Number(state.factoryId)),
    enabled: false,
  });

  useEffect(() => {
    if (location.pathname.includes('home') && !!state.factoryId) {
      refetchDashboard();
    }
  }, [location.pathname, state.factoryId]);

  const refreshStatus = () => {
    refetchDashboard();
    setState((prev) => ({ ...prev, isRefresh: true }));
  };

  return (
    <nav className={'left-menu'}>
      <SidebarNavList items={dashboardItemList} />
      {location.pathname.includes('home') && dashboardData && (
        <StatusBoard
          statusInfo={dashboardData ?? null}
          refresh={refreshStatus}
        />
      )}
    </nav>
  );
}
