import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { emailRegex, phoneRegex } from 'util/regexUitil';

export const mypageUpdateSchema = yupResolver(
  yup.object({
    account: yup.string().defined(),
    name: yup.string().defined(),
    phone: yup.string().trim().matches(phoneRegex, '휴대폰 형식에 맞지 않습니다 ex)010-xxxx-xxxx').required('휴대폰번호를 입력해주세요'),
    email: yup.string().matches(emailRegex, '이메일 형식에 맞지 않습니다.').required('이메일을 입력해주세요'),
  }),
);

export const passwordUpdateSchema = yupResolver(
  yup.object({
    oldPass: yup.string().required('현재 비밀번호를 입력해주세요'),
    newPass: yup
      .string()
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/,
        '비밀번호 설정규칙은 영문,숫자,특수문자의 조합으로 8자리 이상입니다',
      )
      .required('신규 비밀번호를 입력해주세요'),
    confirmPass: yup
      .string()
      .oneOf(
        [yup.ref('newPass')],
        '신규 비밀번호와 확인 비밀번호가 맞지 않습니다',
      )
      .required(),
  }),
);

// TODO password 정규식
// /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/
