import { DashboardStatusResp } from 'api/homeApi';

type StatusBoardProps = {
  statusInfo?: DashboardStatusResp | null;
  refresh: () => void;
};

export default function StatusBoard({
  statusInfo = null,
  refresh,
}: StatusBoardProps) {
  return (
    <div className={'side-status-wrap'}>
      {statusInfo && (
        <table className={'tbl-type-05'}>
          <tbody>
            <tr>
              <td>
                <b>{'현황판'}</b>
              </td>
              <td>
                <button
                  type={'button'}
                  className={'btn-type-01'}
                  onClick={refresh}
                >
                  {'갱신'}
                </button>
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
              >{`작업허가서 (${statusInfo.orderTotal ?? 0})`}</th>
            </tr>
            <tr>
              <td>{'부착중'}</td>
              <td>{statusInfo.orderLock ?? 0}</td>
            </tr>
            <tr>
              <td>{'부착확정'}</td>
              <td>{statusInfo.orderLocked ?? 0}</td>
            </tr>
            <tr>
              <td>{'제거승인'}</td>
              <td>{statusInfo.orderApprove ?? 0}</td>
            </tr>
            <tr>
              <th
                colSpan={2}
              >{`Locker 현황 (${statusInfo.lockerTotal ?? 0}) EA`}</th>
            </tr>
            <tr>
              <td>{'사용'}</td>
              <td>{statusInfo.lockerLock ?? 0}</td>
            </tr>
            <tr>
              <td>{'대기'}</td>
              <td>{statusInfo.lockerUnlock ?? 0}</td>
            </tr>
            <tr>
              <th
                colSpan={2}
              >{`사용자 현황 (${statusInfo.userTotal ?? 0})명`}</th>
            </tr>
            <tr>
              <td>{'작업 중'}</td>
              <td>{statusInfo.userLock ?? 0}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}
